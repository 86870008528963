import React from 'react'
import './button.css'
import ReactGA from 'react-ga'
import PlayStore from '../../images/google-play-badge.svg'

const Android = () => (
  <ReactGA.OutboundLink
    eventLabel="https://play.google.com/store/apps/details?id=com.frekvensapp.native"
    to="https://play.google.com/store/apps/details?id=com.frekvensapp.native"
    target="_blank"
    rel="noopener noreferrer"
  >
    <div
      className="f-button-container"
      style={{ backgroundImage: 'url(' + PlayStore + ')' }}
    />
  </ReactGA.OutboundLink>
)

export default Android
