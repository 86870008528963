import React from 'react'
import './EventListItem.scss'
import { Link } from "gatsby"
import Moment from 'react-moment'
import moment from 'moment'
import 'moment/locale/nb'
import { Icon } from 'react-icons-kit'
import { ticket } from 'react-icons-kit/entypo/ticket'
import EventSchema from '../EventSchema/EventSchema'

const EventListItem = ({ event, eventId }) => (
  <Link to={`/event/${eventId}/`}>
    <div
      className="event-list-item"
      style={{
        backgroundImage:
          'url(' + (event.large_image_url || event.venue_large_image_url) + ')',
      }}
    >
      <div
        className={`event-list-item__title-container ${event.tickets_url &&
          'event-list-item__title-container--has-tickets'}`}
      >
        {event.tickets_url && <Icon icon={ticket} size={28} />}
        &nbsp;{event.name} &#64; {event.venue_name}
      </div>
      <div
        className={`event-list-item__time-container ${event.tickets_url &&
          'event-list-item__time-container--has-tickets'}`}
      >
        {moment(event.start_timestamp).isBefore(moment().endOf('day')) && (
          <Moment fromNow date={event.start_timestamp} />
        )}
        {moment(event.start_timestamp).isAfter(moment().endOf('day')) &&
          moment(event.start_timestamp).isBefore(moment().add(8, 'days')) && (
            <Moment format="dddd" date={event.start_timestamp} />
          )}
        {moment(event.start_timestamp).isAfter(moment().add(8, 'days')) && (
          <Moment format="DD MMMM" date={event.start_timestamp} />
        )}
      </div>
    </div>
    <EventSchema event={event} eventId={eventId} />
  </Link>
)

export default EventListItem
