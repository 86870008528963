import React from 'react'
import ReactGA from 'react-ga'
import './button.css'
import AppStore from '../../images/apple-app-store-badge.svg'

const Apple = () => (
  <ReactGA.OutboundLink
    eventLabel="https://itunes.apple.com/us/app/frekvens/id1367233126?ls=1&mt=8"
    to="https://itunes.apple.com/us/app/frekvens/id1367233126?ls=1&mt=8"
    target="_blank"
    rel="noopener noreferrer"
  >
    <div
      className="f-button-container"
      style={{ backgroundImage: 'url(' + AppStore + ')' }}
    />
  </ReactGA.OutboundLink>
)

export default Apple
