import React from 'react'
import { Link } from "gatsby"
import './hero.scss'
import Android from '../store-buttons/android'
import Apple from '../store-buttons/apple'

const Hero = () => (
  <div className="f-hero-container">
    <div className="f-hero-flex">
      <div className="f-hero-title">
        <h1>OPPDAG KONSERTER I OSLO{process.env.HERO_TITLE}</h1>
        <h2>Søk på over 700 konserter og spillesteder i app</h2>
        <div className="f-hero-button-flex">
          <Android />
          <Apple />
        </div>
      </div>
      <div className="f-hero-mockup" />
    </div>
  </div>
)

export default Hero
