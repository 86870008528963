import React from 'react'

const jsonSearchSchema = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  url: 'https://frekvensapp.com/',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://frekvensapp.com/search/?name={search_term_string}',
    'query-input': 'required name=search_term_string',
  },
}

const SearchSchema = () => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonSearchSchema) }}
    />
  )
}

export default SearchSchema
