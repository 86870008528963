import React from 'react'
import { Link } from "gatsby"
import './foot.css'

const Foot = () => (
  <div className="f-foot-container">
    <a href="mailto:admin+landing@frekvensapp.com">
      <div className="foot-button">KONTAKT OSS</div>
    </a>
    <a
      href="https://docs.google.com/forms/d/e/1FAIpQLScFcQkB8clKfIhZKku2EKXED_E5jzTjq3gOWUPSs4Bb0okWng/viewform?usp=sf_link"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="foot-button">SEND OSS KONSERTER</div>
    </a>
    <Link to="/search">
      <div className="foot-button">SØK KONSERTER</div>
    </Link>
    <a
      href="https://twitter.com/frekvensapp"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="foot-button">TWITTER</div>
    </a>
    <a
      href="https://facebook.com/frekvensapp"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="foot-button">FACEBOOK</div>
    </a>
    <a
      href="https://www.instagram.com/frekvensapp/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="foot-button">INSTAGRAM</div>
    </a>
  </div>
)

export default Foot
