import React from 'react'
import './index.scss'
import Helmet from 'react-helmet'
import Head from '../components/head'
import Hero from '../components/hero'
import Foot from '../components/foot'
import EventListItem from '../components/EventListItem/EventListItem.js'
import SearchSchema from '../components/SearchSchema/SearchSchema.js'
import Layout from "../components/layout/layout.js"

class IndexPage extends React.Component {
  render() {
    const eventList = this.props.data.allInternalToday.edges.map(
      (event, index) => {
        return (
          <div key={index} className="index-event-list__item">
            <EventListItem
              event={event.node}
              eventId={event.node.alternative_id}
            />
          </div>
        )
      }
    )
    return (
      <Layout>
        <div>
          <Helmet>
            <meta
              name="msvalidate.01"
              content="E88718C1314A9F8CBAAC40CFFCE396BF"
            />
            <link rel="canonical" href={`https://frekvensapp.com/`} />
          </Helmet>
          <Hero />
          <div className="index-event-list__sticky">
            <Head />
          </div>

          <div className="index-event-list">{eventList}</div>
          <Foot />
          <SearchSchema />
        </div>
      </Layout>

    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    allInternalToday(
      sort: { fields: [start_timestamp] }
      filter: { alternative_id: { ne: null } }
    ) {
      edges {
        node {
          alternative_id
          name
          venue_id
          url
          tickets_url
          price
          currency
          large_image_url
          start_timestamp
          end_timestamp
          venue_name
          venue_address
          venue_latitude
          venue_longitude
          venue_url
          venue_large_image_url
        }
      }
    }
  }
`
