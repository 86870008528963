import React from 'react'

const EventSchema = ({ event, eventId }) => {
  let eventOffer
  if (event.tickets_url) {
    eventOffer = {
      '@type': 'Offer',
      url: `https://frekvensapp.com/event/${eventId}/`,
      price: Math.round(event.price),
      priceCurrency: 'NOK',
      availability: 'http://schema.org/InStock',
    }
  } else {
    eventOffer = {
      '@type': 'Offer',
      url: `https://frekvensapp.com/event/${eventId}/`,
      price: Math.round(event.price),
      priceCurrency: 'NOK',
      availability: 'http://schema.org/InStock',
    }
  }

  let eventAddress = event.venue_address.split(',')

  let jsonSchema = {
    '@context': 'http://schema.org',
    '@type': 'Event',
    name: event.name,
    url: `https://frekvensapp.com/event/${eventId}/`,
    startDate: event.start_timestamp,
    location: {
      '@type': 'Place',
      name: event.venue_name,
      address: {
        '@type': 'PostalAddress',
        streetAddress: eventAddress[0],
        addressLocality: eventAddress[1].split(' ')[2],
        postalCode: eventAddress[1].split(' ')[1],
        addressRegion: 'Oslo',
        addressCountry: 'Norway',
      },
    },
    image: [event.large_image_url || event.venue_large_image_url],
    // description:
    //   'Join us for an afternoon of Jazz with Santa Clara resident and pianist Andy Lagunoff. Complimentary food and beverages will be served.',
    endDate: event.end_timestamp,
    offers: eventOffer,
    // performer: {
    //   '@type': 'PerformingGroup',
    //   name: 'Andy Lagunoff',
    // },
  }
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonSchema) }}
    />
  )
}

export default EventSchema
