import React from 'react'
import { Link } from "gatsby"
import './head.css'
import Logo from '../../images/logo.svg'
import Android from '../store-buttons/android'
import Apple from '../store-buttons/apple'

const Head = () => (
  <div className="f-head-container">
    <Link to="/">
      <div
        className="f-head-logo"
        style={{ backgroundImage: 'url(' + Logo + ')' }}
      />
    </Link>
    <div className="head__download-buttons">
      <div className="head__download-button">
        <Apple />
      </div>
      <div className="head__download-button">
        <Android />
      </div>
    </div>
  </div>
)

export default Head
